.clockwise {
    transform-box: fill-box;
    transform-origin: 50% 50%;
    animation: spin 3s linear infinite;
    }

    @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    }

.conterclockwise {
    transform-box: fill-box;
    transform-origin: 50% 50%;
    animation: spincount 4s linear infinite;
    }

    @keyframes spincount {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(-360deg); }
    }