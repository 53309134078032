html,
body {
  margin: 0;
  padding: 0;
}

.body {
  display: flex;
  flex-direction: row;
  border-top-width: 1px;
}

.sideBar {
  flex: 0 0 1;
  width: 300px;
  padding: 10px;
  border-right: 1px solid #111;
  height: 100%;
  background: whitesmoke;
  overflow: auto;
  position: sticky;
  top: 0px;
}

.optionBar {
  width: 300px;
  background: white;
}

.sideBarItem {
  border: 1px solid #000;
  border-radius: 3px;
  height: fit-content;
  text-align: left;
  padding-left: 8px;
  margin-top: 10px;
  background: white;
}

.page {
  flex: 1 1 auto;
  padding: 0 20px;
  margin: 20px;
  border: 1px solid rgb(56, 56, 56);
}

.dropZone {
  flex: 0 0 auto;
  height: 10px;
  transition: 200ms all;
  min-height: 10px;
}

.dropZone:nth-of-type(2n) {
  display: none;
}

.dropZone.horizontalDrag {
  width: 40px;
  height: auto;
}

.dropZone:not(.horizontalDrag).isLast {
  flex: 1 1 auto;
  height: 50px;
}

.dropZone.active,
.trashDropZone.active {
  background: #00a2ff;
  transition: 100ms all;
}


.component {
  /* height: 60px;
  padding: 10px;
  background: #aaa; */
  text-align: center;
}

.column {
  /* border: 1px solid blue; */
  flex: 1 1 100%;
}

.columns {
  display: flex;
  /* padding: 20px 0; */
}

.columnContainer {
  display: flex;
}

.base {
  cursor: move;
}

.row {
  /* border: 1px solid red; */
  padding: 0;
}

.trashDropZone {
  position: relative;
  text-align: center;
  padding: 20px 0;
  margin: 0 auto;
  width: 100px;
  height: 100px;
  border: 1px solid purple;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pageContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  background: white;
  /* margin-bottom: 100px; */
}

.stripes {
  background: repeating-linear-gradient(
    45deg,
    #ffffff,
    #ffffff 10px,
    #f0f0f0 10px,
    #f0f0f0 20px
  );
}