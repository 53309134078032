[data-texty] {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  [data-texty-tooltip] {
    margin: 6px 0;
    padding: 4px 10px;
    border-radius: 4px;
    background-color: #222;
    color: #fff;
    z-index: 99999;
  }
  
  [data-texty-arrow] {
    position: absolute;
    bottom: -6px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0;
    border-color: #222 transparent transparent;
  }
  
  [data-texty-arrow*='bottom'] {
    top: -6px;
    bottom: inherit;
    transform: rotate(180deg);
  }
  
  /* disable the builtin tooltip for truncated text on Safari */
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    @supports (not (-ms-ime-align: auto)) {
      .EllipsisText::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
      }
    }
  }